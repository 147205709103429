export const GET_SUPPLIER = 'GET_SUPPLIER';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';

export const reducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
  case GET_SUPPLIER:
    return {
      ...state,
      supplierList: payload.supplierList,
    }
  case ADD_SUPPLIER:
    return {
      ...state,
      supplierList: [payload.newSupplier, ...state.supplierList],
    }
  case UPDATE_SUPPLIER:
    return {
      ...state,
      supplierList: [
        ...state.supplierList.slice(0, state.supplierList.findIndex((supplier) => (
          supplier.id === payload.newSupplier.id))),
        { ...payload.newSupplier },
        ...state.supplierList.slice(state.supplierList.findIndex((supplier) => (
          supplier.id === payload.newSupplier.id)) + 1),
      ],
    }
  case DELETE_SUPPLIER:
    return {
      ...state,
      supplierList: state.supplierList.filter((supplier) => supplier.id !== payload.id),
    }
  default:
    return state;
  }
}