import React from 'react'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from 'recharts';
import PropTypes from 'prop-types';

const SupplierChart = ({ data }) => (
  <>
    <p
      style={{
        textAlign: 'center',
        fontSize: 18,
      }}
    >
      Suppliers
    </p>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis padding={{ left: 5, right: 5 }} dataKey="supplierName" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" name="Total purchase in PHP" dataKey="totalPhp" stroke="#30336b" />
        <Line type="monotone" name="Total purchase in USD" dataKey="totalUsd" stroke="#f0932b" />
      </LineChart>
    </ResponsiveContainer>
  </>
)
SupplierChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default SupplierChart
