/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import UtilsContext from './context/UtilsContext';
import PurchaseOrderContext from './context/PurchaseOrderContext/PurchaseOrderContext';
import PurchaseRequestContext from './context/PurchaseRequestContext/PurchaseRequestContext';
import SupplierContextWrapper from './context/SupplierContext/SupplierContextWrapper';

import Login from './views/Login';
import Error from './error/Error';
import './assets/css/Login.css';
import './App.css';

import { routes } from './config/config';

library.add(fab, fas);

function App() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <UtilsContext>
            <PurchaseRequestContext>
              <PurchaseOrderContext>
                <SupplierContextWrapper>
                  <Switch>
                    <Route path="/" render={(prop) => <Login {...prop} />} exact />
                    {routes.map((data) => (
                      <Route
                        key={data.title}
                        path={data.path}
                        render={(prop) => <data.component {...prop} title={data.title} />}
                      />
                    ))}
                    <Route render={(prop) => <Error {...prop} />} exact />
                  </Switch>
                </SupplierContextWrapper>
              </PurchaseOrderContext>
            </PurchaseRequestContext>
          </UtilsContext>
        </Suspense>
      </Switch>
    </Router>
  );
}

export default App;
