import React, { lazy, useEffect, useState } from 'react';
import axios from 'axios';
import { Input } from 'antd';

import { API } from '../config/config';
import { displayErrors } from '../config/display';
import { headers } from '../config/token';
import Layout from '../layout/Layout';

const Logs = lazy(() => import('../components/Logs/Logs'));
const LogsView = () => {
  const [logs, setLogs] = useState({ data: [], size: 0 });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [paginate, setPaginate] = useState({ page: 1, pageSize: 20 });

  const loadData = (page, pageSize) => {
    setLoading(true);

    let searchParams = '';
    if (search !== '' || search !== null) {
      searchParams = `&search=${search}`;
    }

    axios
      .get(
        `${API}psms/logs?page=${page}&pageSize=${pageSize}${searchParams}`,
        headers(),
      )
      .then((res) => {
        const { data } = res;
        setLogs({
          data: data.logs,
          size: data.logsLength,
        });
        setLoading(false);
      })
      .catch((err) => displayErrors(err));
  };

  useEffect(() => {
    loadData(paginate.page, paginate.pageSize);
  }, []);

  const onPageChange = (page, pageSize) => {
    setPaginate({ ...paginate, page });
    loadData(page, pageSize);
  };

  const onSizeChange = (page, pageSize) => {
    setPaginate({ page, pageSize });
    loadData(page, pageSize);
  };
  const { page, pageSize } = paginate;
  return (
    <>
      <br />
      <h2>Logs</h2>
      <Input.Search
        style={{ marginBottom: 10, width: '30%' }}
        placeholder="Search here..."
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => loadData(page, pageSize)}
      />
      <Logs
        loading={loading}
        logs={logs.data}
        size={logs.size}
        paginate={paginate}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
      />
    </>
  );
};

export default Layout(LogsView);
