import PrPricingView from '../views/PrPricingView';
import PoView from '../views/PoView';
import PrView from '../views/PrView';
import LogsView from '../views/LogsView';
import SupplierView from '../views/SupplierView';
import PurchasesReport from '../views/PurchasesReport';

export const brandnameLg = { name: 'PSM', suffix: 'S' }; // BRANDNAME TO BE DISPLAY WHEN ON LG MODE,MAX OF 10 CHARACTERS FOR NAME AND 5 FOR SUFFIX
export const brandnameSm = 'PSMS'; // SHORTER NAME or acronym for BRANDNAME TO BE DISPLAY WHEN ON MOBILE SIZE, MAX OF 8 CHARACTERS ONLY
export const redirectPath = '/prlist'; // redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; // api link

// https://ant.design/components/icon/ for icons
export const routes = [
  // array for routes
  {
    component: PrPricingView,
    title: 'Purchase request',
    icon: 'list-ul',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/prlist',
  },
  {
    component: PrView,
    title: 'Pending request',
    icon: 'list',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/prprice',
  },
  {
    component: PoView,
    title: 'Purchase order',
    icon: 'clipboard-list',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/po',
  },
  {
    component: SupplierView,
    title: 'Supplier',
    icon: 'truck',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/supplier',
  },
  {
    component: PurchasesReport,
    title: 'Purchases Report',
    icon: 'chart-line',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/purchasesreport',
  },
  {
    component: LogsView,
    title: 'Logs',
    icon: 'history',
    view: true, // change to false if you dont want this route to be on sidebar menu
    path: '/logs',
  },
];
