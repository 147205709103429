import React, {
  useState,
  useEffect,
  useContext,
  Suspense,
} from 'react';
import Layout from '../layout/Layout';
import { PrContext } from '../context/PurchaseRequestContext/PurchaseRequestContext';
import { Utils } from '../context/UtilsContext';

import searchArray from '../components/searchArray';
import PurchaseRequestTable from '../components/PurchaseRequest/PurchaseRequestTable';
import Filter from '../components/Filter/Filter';

const PurchaseRequestModal = React.lazy(() => import('../components/PurchaseRequest/PurchaseRequestModal'));

function PrPricingView() {
  const { prValue, getPrList, getPrDetails } = useContext(PrContext);
  const { setLoading } = useContext(Utils);

  const [filter, setFilter] = useState({
    search: '',
    throttle: 500,
  });
  useEffect(() => getPrList(setLoading, 1000), []);
  const [modalData, setModalData] = useState({ data: {}, visible: false });
  const { prList } = prValue;

  const filteredPrList = searchArray(filter.search, prList, ['prNum', 'joNum', 'poNum']);
  // modal
  const openModal = (data) => setModalData({ visible: true, data });
  const closeModal = () => setModalData({ visible: false, data: {} });
  const refreshContent = (val = filter.throttle) => getPrList(setLoading, val)

  return (
    <>
      <br />
      <h2>PURCHASE REQUEST LIST</h2>
      <Filter
        filter={filter}
        setFilter={setFilter}
        refreshContent={refreshContent}
      />
      <PurchaseRequestTable
        prList={filteredPrList}
        openModal={openModal}
      />

      {modalData.visible && (
        <Suspense fallback={<div>Loading Modal...</div>}>
          <PurchaseRequestModal
            visible={modalData.visible}
            data={modalData.data}
            getPrDetails={getPrDetails}
            closeModal={closeModal}
          />
        </Suspense>
      )}
    </>
  );
}

export default Layout(PrPricingView);
