import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'antd';
import searchArray from '../searchArray';

const SupplierTable = ({ data }) => {
  const columns = [
    {
      key: 'supplierName',
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      align: 'center',
    },
    {
      key: 'totalUsd',
      title: 'Total Purchased PHP',
      dataIndex: 'totalUsd',
      align: 'center',
    },
    {
      key: 'totalPhp',
      title: 'Total Purchased USD',
      dataIndex: 'totalPhp',
      align: 'center',
    },
  ];
  const [search, setSearch] = useState('');
  const filtered = searchArray(search, data, ['supplierName']);

  return (
    <div>
      <Input.Search
        style={{
          width: '60%',
          minWidth: 400,
          margin: '10px 0 10px 0',
        }}
        placeholder="Search supplier"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Table
        dataSource={filtered}
        size="middle"
        pagination={false}
        columns={columns}
        rowKey="supplierName"
        style={{
          overflowX: 'auto',
          overFlowY: 'auto',
          maxHeight: 500,
        }}
        bordered
      />
    </div>
  )
}
SupplierTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default SupplierTable
