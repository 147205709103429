import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  reducer,
  GET_SUPPLIER,
  ADD_SUPPLIER,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
} from './SupplierReducer';
import { headers } from '../../config/token';
import { displayErrors, displayNotification } from '../../config/display';

export const SupplierContext = createContext({});

function SupplierContextWrapper({ children }) {
  const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';
  const [supplierState, dispatch] = useReducer(reducer, {
    supplierList: [],
  });

  const getSupplierList = (setLoading) => {
    setLoading(true);
    Axios.get(`${API}psms/supplier`, headers())
      .then((res) => {
        const { supplierList } = res.data;
        dispatch({
          type: GET_SUPPLIER,
          payload: {
            supplierList,
          },
        })
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        displayErrors(err);
      })
  }

  const addSupplier = (values, cancelEditSupplier, setLoading, setSubmitting) => {
    setLoading(true);
    Axios.post(`${API}psms/supplier`, values, headers())
      .then((res) => {
        const { newSupplier, message } = res.data;
        dispatch({
          type: ADD_SUPPLIER,
          payload: {
            newSupplier,
          },
        })
        displayNotification('success', message);
        setLoading(false);
        cancelEditSupplier();
        setSubmitting(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmitting(false);
        displayErrors(err);
      })
  }

  const updateSupplier = (values, cancelEditSupplier, setLoading, setSubmitting) => {
    setLoading(true);
    Axios.put(`${API}psms/supplier/${values.id}`, values, headers())
      .then((res) => {
        const { newSupplier, message } = res.data;
        dispatch({
          type: UPDATE_SUPPLIER,
          payload: {
            newSupplier,
          },
        })
        setSubmitting(false);
        displayNotification('success', message);
        cancelEditSupplier();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSubmitting(false);
        displayErrors(err);
      })
  }

  const deleteSupplier = (id, setLoading) => {
    setLoading(true);
    Axios.delete(`${API}psms/supplier/${id}`, headers())
      .then((res) => {
        const { message } = res.data;
        dispatch({
          type: DELETE_SUPPLIER,
          payload: {
            id,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        displayErrors(err);
      })
  }

  return (
    <SupplierContext.Provider
      value={{
        supplierState,
        getSupplierList,
        addSupplier,
        updateSupplier,
        deleteSupplier,
      }}
    >
      {children}
    </SupplierContext.Provider>
  )
}

SupplierContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SupplierContextWrapper
