import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  reducer,
  GET_PR,
  GET_PRPRICE,
  ADD_PRPRICE,
  EDIT_PRPRICE,
  DELETE_PRPRICE,
  ADD_PO,
} from './PurchaseRequestReducer';
import { headers } from '../../config/token';
import { displayErrors, displayNotification } from '../../config/display';

export const PrContext = createContext({});

function PurchaseRequestContext({ children }) {
  const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';
  const defaultValue = {
    prList: [],
    prListLength: 0,
    prPriceList: [],
    prPriceListLength: 0,
    supplierOption: [],
  }
  const getFilterParams = (filter) => {
    let params = '';
    Object.keys(filter).forEach((key) => {
      params += filter[key] ? `&${key}=${filter[key]}` : ''
    })
    return params;
  }
  const [prValue, dispatch] = useReducer(reducer, defaultValue);
  // functions
  const getPrList = (setLoading, recordLimit = 500) => {
    setLoading(true);
    Axios.get(`${API}psms/pr?recordCount=${recordLimit}`, headers())
      .then((res) => {
        const { prList, prListLength } = res.data;

        dispatch({
          type: GET_PR,
          payload: {
            prList,
            prListLength,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getPrListPrice = (setLoading, filter = {}, recordLimit = 500) => {
    setLoading(true);
    const parameters = getFilterParams(filter);
    Axios.get(`${API}psms/prprice?recordCount=${recordLimit}${parameters}`, headers())
      .then((res) => {
        const { prPriceList, prPriceListLength, supplierList } = res.data;
        dispatch({
          type: GET_PRPRICE,
          payload: {
            prPriceListLength,
            prPriceList,
            supplierList,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getPrDetails = (id) => Axios.get(`${API}psms/pr/${id}`, headers())
    .then((res) => ({ ...res.data }))
    .catch((err) => {
      displayErrors(err);
      return new Error('Something wrong with the server!');
    })


  const getItemSupplierPrice = (prId, supplierId) => Axios.get(`${API}psms/pr/${prId}/${supplierId}`, headers())
    .then((res) => {
      const { prPricing } = res.data;
      return { prPricing };
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Something wrong with the server!');
    })

  const addEditPriceToPr = (values, setLoading, closeModal, setSubmitting) => {
    setLoading(true);
    const add = () => Axios.post(`${API}psms/pr`, values, headers());
    const edit = () => Axios.put(`${API}psms/pr/${values.id}`, values, headers());

    const reqtype = values.price_id ? edit : add;
    reqtype()
      .then((res) => {
        const { newPr, message } = res.data;
        dispatch({
          type: values.price_id ? EDIT_PRPRICE : ADD_PRPRICE,
          payload: { newPr },
        })
        displayNotification('success', message);
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
        setSubmitting(false);
      })
  }

  const deletePrPrice = (id, setLoading) => {
    setLoading(true);

    Axios.delete(`${API}psms/pr/${id}`, headers())
      .then((res) => {
        const { message } = res.data;
        dispatch({
          type: DELETE_PRPRICE,
          payload: {
            id,
          },
        })
        displayNotification('success', message);
        setLoading(false);
      })
      .catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const fetchApprovalList = async (setLoading, id, type = 'pr') => {
    setLoading(true);
    let URL = `${API}psms/approval/${id}`;

    if (type === 'po') URL = `${API}psms/po/approval/${id}`;
    try {
      const res = await Axios.get(URL, headers());
      const { approvalList, approverList } = res.data;
      return { approvalList, approverList };
    } catch (err) {
      displayErrors(err);
      throw new Error('Error on server');
    } finally {
      setLoading(false);
    }
  }

  const createApprovalRequest = (values) => Axios.post(`${API}psms/approval`, values, headers())
    .then((res) => {
      const { newApprovalRequest, newPr, message } = res.data;
      dispatch({
        type: EDIT_PRPRICE,
        payload: { newPr },
      });
      displayNotification('success', message);
      return { newApprovalRequest };
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Error');
    })

  const deleteApprovalRequest = (id) => Axios.delete(`${API}psms/approval/${id}`, headers())
    .then((res) => {
      const { message, newPr } = res.data;
      dispatch({
        type: EDIT_PRPRICE,
        payload: { newPr },
      });
      displayNotification('success', message);
    })
    .catch((err) => {
      displayErrors(err);
      throw new Error('Error');
    })

  const getPrDetailsForPO = async (prsID) => {
    try {
      const res = await Axios.post(`${API}psms/po/preview`, { prsID }, headers());
      const {
        poSeries, prNumbers, supplierDetails, poItems,
      } = res.data;
      return {
        poSeries, prNumbers, supplierDetails, poItems,
      };
    } catch (err) {
      displayErrors(err);
      return new Error('Server error');
    }
  }

  const addPurchaseOrderToPR = (values, setLoading, onCancelSelectingPO, closeModal) => {
    setLoading(true);

    Axios.post(`${API}psms/po`, values, headers())
      .then((res) => {
        const { addedIDs, message } = res.data;
        dispatch({
          type: ADD_PO,
          payload: {
            addedIDs,
          },
        })
        displayNotification('success', message);
        setLoading(false);
        onCancelSelectingPO();
        closeModal();
      }).catch((err) => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const printPr = (id, setLoading) => {
    setLoading(true);

    Axios({
      url: `${API}psms/print/${id}`,
      method: 'get',
      responseType: 'blob',
      ...headers(),
    })
      .then((res) => {
        displayNotification('success', 'File successfully generated');
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [res.data],
          { type: 'application/pdf' },
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) displayNotification('error', 'Purchase request is not printable');
      })
      .finally(() => setLoading(false));
  }

  return (
    <PrContext.Provider
      value={{
        prValue,
        getPrList,
        getPrListPrice,
        getPrDetails,
        getItemSupplierPrice,
        addEditPriceToPr,
        deletePrPrice,
        fetchApprovalList,
        createApprovalRequest,
        deleteApprovalRequest,
        getPrDetailsForPO,
        addPurchaseOrderToPR,
        printPr,
      }}
    >
      {children}
    </PrContext.Provider>
  )
}

PurchaseRequestContext.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PurchaseRequestContext
