import React from 'react';
import {
  Collapse, Row, Col, Button, Select,
} from 'antd';
import PropTypes from 'prop-types';

const { Panel } = Collapse;
const { Option } = Select;
const yearOpt = [2015, 2016, 2017, 2018, 2019, 2020, 2021,
  2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

const Filter = ({
  filter, setFilter, supplierList = [], onFilter, onResetFilter,
}) => (
  <Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[0]}>
    <Panel header="ADVANCE FILTER" key="1">
      <Row gutter={16}>
        <Col lg={{ span: 6 }}>
            Supplier :
          <br />
          <Select
            value={filter.supplier}
            onChange={(supplier) => setFilter({
              ...filter,
              supplier,
            })}
            style={{ width: '100%' }}
          >
            <Option key="a000a" value={null}>All</Option>
            {supplierList.map((supplier) => (
              <Option value={supplier.id} key={supplier.id}>
                {supplier.supplierName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col lg={{ span: 6 }}>
            Currency :
          <br />
          <Select
            onChange={(currency) => setFilter({
              ...filter,
              currency,
            })}
            value={filter.currency}
            style={{ width: '100%' }}
          >
            <Option value={null}>All</Option>
            <Option value="PHP">PHP</Option>
            <Option value="USD">USD</Option>
          </Select>
        </Col>
        <Col lg={{ span: 6 }}>
            Month :
          {' '}
          <br />
          <Select
            onChange={(month) => setFilter({ ...filter, month })}
            value={filter.month}
            style={{ width: '100%' }}
          >
            <Option value={null}>All</Option>
            <Option value={1}>Jan</Option>
            <Option value={2}>Feb</Option>
            <Option value={3}>Mar</Option>
            <Option value={4}>Apr</Option>
            <Option value={5}>May</Option>
            <Option value={6}>Jun</Option>
            <Option value={7}>Jul</Option>
            <Option value={8}>Aug</Option>
            <Option value={9}>Sep</Option>
            <Option value={10}>Oct</Option>
            <Option value={11}>Nov</Option>
            <Option value={12}>Dec</Option>
          </Select>
        </Col>
        <Col lg={{ span: 6 }}>
            Year :
          {' '}
          <br />
          <Select
            value={filter.year}
            style={{ width: '100%' }}
            onChange={(year) => setFilter({ ...filter, year })}
          >
            <Option value={null}>All</Option>
            {yearOpt.map((val) => <Option key={val} value={val}>{val}</Option>)}
          </Select>
        </Col>
      </Row>
      <Row gutter={16}>
        {Object.prototype.hasOwnProperty.call(filter, 'poStatus') && (
          <Col lg={{ span: 6 }}>
            Status :
            <br />
            <Select
              onChange={(poStatus) => setFilter({
                ...filter,
                poStatus,
              })}
              value={filter.poStatus}
              style={{ width: '100%' }}
            >
              <Option value={null}>All</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="OPEN">OPEN</Option>
              <Option value="PARTIAL">PARTIAL</Option>
              <Option value="DELIVERED">DELIVERED</Option>
            </Select>
          </Col>
        )}
        {Object.prototype.hasOwnProperty.call(filter, 'poItemStatus') && (
          <Col lg={{ span: 6 }}>
            Status :
            <br />
            <Select
              onChange={(poItemStatus) => setFilter({
                ...filter,
                poItemStatus,
              })}
              value={filter.poItemStatus}
              style={{ width: '100%' }}
            >
              <Option value={null}>All</Option>
              <Option value="OPEN">OPEN</Option>
              <Option value="DELIVERED">DELIVERED</Option>
            </Select>
          </Col>
        )}
        <Col lg={{ span: 6 }}>
          <br />
          <Button icon="filter" onClick={onFilter} className="bl-cl" block>Filter</Button>
        </Col>
        <Col lg={{ span: 6 }}>
          <br />
          <Button className="bl-cl" onClick={onResetFilter} icon="undo" block>Reset</Button>
        </Col>
      </Row>
    </Panel>
  </Collapse>
);

Filter.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilter: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  supplierList: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Filter
