import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Spin,
  DatePicker,
  Button,
} from 'antd';
import moment from 'moment';
import Layout from '../layout/Layout';
import { PoContext } from '../context/PurchaseOrderContext/PurchaseOrderContext';
import ItemChart from '../components/PurchasesReport/ItemChart';
import SupplierChart from '../components/PurchasesReport/SupplierChart';
import ItemsTable from '../components/PurchasesReport/ItemsTable';
import SupplierTable from '../components/PurchasesReport/SupplierTable';

const { RangePicker } = DatePicker;
const PurchasesReport = () => {
  const { getPurchasesReport, exportPurchasesReportCsv } = useContext(PoContext);
  const [date, setDate] = useState([
    moment().subtract(30, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({
    items: [],
    supplierTotal: [],
  })

  useEffect(() => {
    setLoading(true);
    getPurchasesReport(date)
      .then((res) => {
        const { items, supplierTotal } = res.data;
        setReportData((rec) => ({
          ...rec,
          items,
          supplierTotal,
        }))
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [date]);
  return (
    <Spin spinning={loading}>
      <br />
      <h2>PURCHASES REPORT</h2>
      <section>
        <div>
          <p style={{ margin: 0, fontSize: 15 }}>Select date:</p>
          <RangePicker
            format="YYYY-MM-DD"
            value={[date[0] ? moment(date[0], 'YYYY-MM-DD') : null,
              date[1] ? moment(date[1], 'YYYY-MM-DD') : null]}
            onChange={(date, dateString) => setDate([...dateString])}
          />
          <Button
            onClick={() => exportPurchasesReportCsv(setLoading, date)}
            style={{ margin: '0 5px' }}
            icon="file-excel"
            className="bl-cl"
          >
            Export
          </Button>
        </div>
        <hr />
        <Row gutter={12}>
          <Col lg={{ span: 12 }}>
            <ItemChart data={reportData.items} />
            <hr />
            <ItemsTable
              data={reportData.items}
            />
          </Col>
          <Col lg={{ span: 12 }}>
            <SupplierChart data={reportData.supplierTotal} />
            <hr />
            <SupplierTable
              data={reportData.supplierTotal}
            />
          </Col>
        </Row>
      </section>
    </Spin>
  )
}

export default Layout(PurchasesReport)
