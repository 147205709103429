import React, {
  useState,
  Suspense,
  lazy,
} from 'react';
import { Switch } from 'antd';
import Layout from '../layout/Layout';

const PoDetailsView = lazy(() => import('./PoDetailsView'));
const PoItemsView = lazy(() => import('./PoItemsView'));
const PoView = () => {
  const [viewPo, setView] = useState(true);

  return (
    <>
      <br />
      <Switch
        checkedChildren="Show PO"
        unCheckedChildren="Show Items"
        onChange={(checked) => setView(checked)}
        defaultChecked
      />
      <Suspense
        fallback={<div>Loading content...</div>}
      >
        {viewPo ? <PoDetailsView /> : <PoItemsView />}
      </Suspense>
    </>
  )
}

export default Layout(PoView)
