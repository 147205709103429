/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import {
  Table,
  Tooltip,
  Button,
  Icon,
  Badge,
} from 'antd';
import PropTypes from 'prop-types';
import Confirm from '../Confirm';
// 
const PurchaseRequestPriceTable = (props) => {
  const {
    prList,
    openModal,
    deletePrPrice,
    setLoading,
    isSelecting,
    setSelectedRows,
    printPr,
    supplierOption,
  } = props;

  const columns = [
    {
      key: 'printBtn',
      render: (data) => {
        if (data.status !== 'APPROVED' && data.status !== 'WITH PO') {
          return (
            <Tooltip
              placement="right"
              title="Not printable unless approved."
            >
              <Icon className="bl-cl" type="stop" />
            </Tooltip>
          )
        }

        return (
          <Tooltip
            placement="right"
            title="Print purchase request"
          >
            <Button
              onClick={() => printPr(data.price_id, setLoading)}
              size="small"
              icon="printer"
              className="bl-cl"
            />
          </Tooltip>
        )
      },
      width: '2%',
      align: 'center',
    },
    {
      key: 'requestBtn',
      render: (data) => (
        <Tooltip
          placement="right"
          title="View approval request/s"
        >
          <Badge dot={!!data.hasRequest}>
            <Button
              onClick={() => openModal(data, 'approval')}
              size="small"
              icon="file-done"
              className="bl-cl"
            />
          </Badge>
        </Tooltip>
      ),
      width: '2%',
      align: 'center',
    },
    {
      key: 'funcBtn',
      render: (data) => (
        <Tooltip
          placement="right"
          title="Edit &amp; View record"
        >
          <Button
            onClick={() => openModal(data, 'pr')}
            size="small"
            icon="edit"
            className="bl-cl"
          />
        </Tooltip>
      ),
      width: '2%',
      align: 'center',
    },
    {
      key: 'deleteBtn',
      render: (data) => {
        if (data.status === 'REJECTED'
          || data.status === 'WITH PO') return <Icon type="stop" className="bl-cl" />

        return (
          <Tooltip
            placement="right"
            title="Delete record"
          >
            <Button
              onClick={() => Confirm(deletePrPrice, null, data.price_id, setLoading)}
              size="small"
              icon="delete"
              className="red-cl"
            />
          </Tooltip>
        )
      },
      width: '2%',
      align: 'center',
    },
    {
      key: 'status',
      title: 'Status',
      filters: [
        {
          text: 'PENDING',
          value: 'PENDING',
        },
        {
          text: 'APPROVED',
          value: 'APPROVED',
        },
        {
          text: 'NO REQUEST',
          value: 'NO REQUEST',
        },
        {
          text: 'REJECTED',
          value: 'REJECTED',
        },
        {
          text: 'WITH PO',
          value: 'WITH PO',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (data) => {
        let color = '#27ae60';
        if (data.status === 'APPROVED' || data.status === 'WITH PO') color = '#218c74';
        else if (data.status === 'REJECTED') color = '#c0392b';
        return <div style={{ color, fontWeight: 750 }}>{data.status}</div>
      },
      sorter: (a, b) => {
        const A = a.status ? a.status : '';
        const B = b.status ? b.status : '';
        return A.localeCompare(B);
      },
      sortDirections: ['descend', 'ascend'],
      align: 'center',
    },
    {
      key: 'itemCount',
      title: 'Item/s',
      dataIndex: 'itemCount',
      align: 'center',
    },
    {
      key: 'pr_num',
      title: 'Purchase Request',
      dataIndex: 'prNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.prNum ? a.prNum : '';
        const B = b.prNum ? b.prNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'supplierLabel',
      title: 'Supplier',
      dataIndex: 'supplierLabel',
      align: 'center',
      filters: supplierOption.map((supplier) => ({
        text: supplier.supplierName,
        value: supplier.id,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.supplierLabel ? a.supplierLabel : '';
        const B = b.supplierLabel ? b.supplierLabel : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'po_num',
      title: 'Purchase Order',
      dataIndex: 'poNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.poNum ? a.poNum : '';
        const B = b.poNum ? b.poNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'jo_num',
      title: 'Job Order',
      dataIndex: 'joNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.joNum ? a.joNum : '';
        const B = b.joNum ? b.joNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'currency',
      title: 'Currency',
      dataIndex: 'currency',
      align: 'center',
      sorter: (a, b) => {
        const A = a.currency ? a.currency : '';
        const B = b.currency ? b.currency : '';
        return A.localeCompare(B);
      },
      filters: [
        {
          text: 'PHP',
          value: 'PHP',
        },
        {
          text: 'USD',
          value: 'USD',
        },
      ],
      onFilter: (value, record) => record.currency.indexOf(value) === 0,
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      filters: [
        {
          text: 'Current Week',
          value: 'week',
        },
        {
          text: 'Current Month',
          value: 'month',
        },
        {
          text: 'Current Year',
          value: 'year',
        },
      ],
      onFilter: (value, record) => {
        const date = moment();
        const recordDate = moment(record.date);

        if (value === 'week') return recordDate.week() === date.week();
        if (value === 'month') return recordDate.format('MM') === date.format('MM');
        if (value === 'year') return recordDate.format('YYYY') === date.format('YYYY');
      },
      sorter: (a, b) => {
        const A = a.date ? a.date : null;
        const B = b.date ? b.date : null;
        return moment(A).unix() - moment(B).unix();
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRows, selectedRowsData) => setSelectedRows(selectedRowsData),
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'APPROVED', // Column configuration not to be checked
    }),
  }

  return (
    <Table
      bodyStyle={{
        fontSize: 12,
        overflowX: 'auto',
      }}
      rowSelection={isSelecting ? rowSelection : null}
      columns={columns}
      dataSource={prList}
      size="small"
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50'],
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        position: 'both',
      }}
      bordered
    />
  )
}

PurchaseRequestPriceTable.propTypes = {
  prList: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierOption: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  deletePrPrice: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  printPr: PropTypes.func.isRequired,
}

export default PurchaseRequestPriceTable;
