import React, {
  useState,
  useContext,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import { Row, Col, Input } from 'antd';
import { SupplierContext } from '../context/SupplierContext/SupplierContextWrapper';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import searchArray from '../components/searchArray';

const SupplierListTable = lazy(() => import('../components/Supplier/SupplierListTable'));
const SupplierForm = lazy(() => import('../components/Supplier/SupplierForm'));

const SupplierView = () => {
  const {
    supplierState: {
      supplierList,
    },
    getSupplierList,
    addSupplier,
    updateSupplier,
    deleteSupplier,
  } = useContext(SupplierContext);
  const { setLoading } = useContext(Utils);
  const values = {
    name: '',
    address: '',
    tin: '',
    attention: '',
    paymentTerms: '',
  }
  const [defaultVal, setDefaultval] = useState(values);
  const [search, setSearch] = useState('');
  const selectSupplier = (data) => setDefaultval(data);
  const cancelEditSupplier = () => setDefaultval(values);

  const filteredSupplier = searchArray(search, supplierList, ['name', 'address'])
  useEffect(() => getSupplierList(setLoading), []);
  return (
    <>
      <br />
      <h2>PURCHASE REQUEST LIST</h2>
      <Row gutter={16}>
        <Col lg={{ span: 8 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <SupplierForm
              defaultVal={defaultVal}
              cancelEditSupplier={cancelEditSupplier}
              addSupplier={addSupplier}
              updateSupplier={updateSupplier}
              setLoading={setLoading}
            />
          </Suspense>
        </Col>
        <Col lg={{ span: 16 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Input.Search
              style={{ marginBottom: 10, width: '50%', marginTop: 25 }}
              value={search}
              placeholder="Input search value"
              onChange={(e) => setSearch(e.target.value)}
            />
            <SupplierListTable
              supplierList={filteredSupplier}
              selectSupplier={selectSupplier}
              setLoading={setLoading}
              deleteSupplier={deleteSupplier}
            />
          </Suspense>
        </Col>
      </Row>
    </>
  )
}

export default Layout(SupplierView)
