export const GET_PR = 'GET_PR';
export const GET_PRPRICE = 'GET_PRPRICE';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const ADD_PRPRICE = 'ADD_PRPRICE';
export const EDIT_PRPRICE = 'EDIT_PRPRICE';
export const DELETE_PRPRICE = 'DELETE_PRPRICE';
export const ADD_PO = 'ADD_PO';

export const reducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
  case GET_PR:
    return {
      ...state,
      prList: payload.prList,
      prListLength: payload.prListLength,
    }
  case GET_PRPRICE:
    return {
      ...state,
      prPriceList: payload.prPriceList,
      prPriceListLength: payload.prPriceListLength,
      supplierOption: payload.supplierList,
    }
  case ADD_PRPRICE:
    return {
      ...state,
      prList: [
        ...state.prList.slice(0, state.prList.findIndex((pr) => pr.id === payload.newPr.id)),
        { ...payload.newPr },
        ...state.prList.slice(state.prList.findIndex((pr) => pr.id === payload.newPr.id) + 1),
      ],
    }
  case EDIT_PRPRICE:
    return {
      ...state,
      prPriceList: [
        ...state.prPriceList.slice(0,
          state.prPriceList.findIndex((pr) => pr.id === payload.newPr.id)),
        { ...payload.newPr },
        ...state.prPriceList.slice(state.prPriceList.findIndex((pr) => pr.id === payload.newPr.id)
          + 1),
      ],
    }
  case DELETE_PRPRICE:
    return {
      ...state,
      prPriceList: state.prPriceList.filter((pr) => pr.price_id !== payload.id),
    }
  case ADD_PO:
    return {
      ...state,
      prPriceList: state.prPriceList.map((pr) => {
        if (payload.addedIDs.includes(pr.price_id)) {
          return {
            ...pr,
            status: 'WITH PO',
          }
        } return pr;
      }),
    }
  default:
    return state;
  }
}
