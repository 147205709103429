import React from 'react'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';

const ItemChart = ({ data }) => (
  <>
    <p
      style={{
        textAlign: 'center',
        fontSize: 18,
      }}
    >
      Most purchased items
    </p>
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="specs" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7ed6df" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#7ed6df" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis padding={{ left: 5, right: 5 }} dataKey="materialSpecification" />
        <YAxis />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          name="Total purchased quantity"
          type="monotone"
          dataKey="totalQtyBought"
          stroke="#30336b"
          fillOpacity={1}
          fill="url(#specs)"
        />
      </AreaChart>
    </ResponsiveContainer>
  </>
)
ItemChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default ItemChart
