/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import {
  Table,
  Tooltip,
  Button,
  Icon,
} from 'antd';
import PropTypes from 'prop-types';

const PurchaseRequestTable = (props) => {
  const {
    prList,
    openModal,
  } = props;

  const columns = [
    {
      key: 'funcBtn',
      render: (data) => {
        if (data.status !== 'W/ PRICE') {
          return (
            <Tooltip
              placement="right"
              title="Add supplier &amp; pricing"
            >
              <Button
                onClick={() => openModal(data)}
                size="small"
                icon="form"
                className="bl-cl"
              />
            </Tooltip>
          )
        }

        return <Icon type="stop" className="bl-cl" />
      },
      width: 10,
      align: 'center',
    },
    {
      key: 'status',
      title: 'Status',
      filters: [
        {
          text: 'NO PRICE',
          value: 'NO PRICE',
        },
        {
          text: 'W/ PRICE',
          value: 'W/ PRICE',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (data) => {
        const color = data.status === 'NO PRICE' ? '#27ae60' : '#218c74';
        return <div style={{ color, fontWeight: 750 }}>{data.status}</div>
      },
      sorter: (a, b) => {
        const A = a.status ? a.status : '';
        const B = b.status ? b.status : '';
        return A.localeCompare(B);
      },
      sortDirections: ['descend', 'ascend'],
      align: 'center',
    },
    {
      key: 'itemCount',
      title: 'Item/s',
      dataIndex: 'itemCount',
      align: 'center',
    },
    {
      key: 'po_num',
      title: 'Purchase Order',
      dataIndex: 'poNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.poNum ? a.poNum : '';
        const B = b.poNum ? b.poNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'jo_num',
      title: 'Job Order',
      dataIndex: 'joNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.joNum ? a.joNum : '';
        const B = b.joNum ? b.joNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'pr_num',
      title: 'Purchase Request',
      dataIndex: 'prNum',
      align: 'center',
      sorter: (a, b) => {
        const A = a.prNum ? a.prNum : '';
        const B = b.prNum ? b.prNum : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'customer',
      title: 'Customer',
      dataIndex: 'customer',
      align: 'center',
      sorter: (a, b) => {
        const A = a.customer || '';
        const B = b.customer || '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      filters: [
        {
          text: 'Current Week',
          value: 'week',
        },
        {
          text: 'Current Month',
          value: 'month',
        },
        {
          text: 'Current Year',
          value: 'year',
        },
      ],
      onFilter: (value, record) => {
        const date = moment();
        const recordDate = moment(record.date);

        if (value === 'week') return recordDate.week() === date.week();
        if (value === 'month') return recordDate.format('MM') === date.format('MM');
        if (value === 'year') return recordDate.format('YYYY') === date.format('YYYY');
      },
      sorter: (a, b) => {
        const A = a.date ? a.date : null;
        const B = b.date ? b.date : null;
        return moment(A).unix() - moment(B).unix();
      },
    },
    {
      key: 'remarks',
      title: 'Remarks',
      align: 'center',
      render: (data) => ({
        props: {
          style: {
            maxWidth: 200,
          },
        },
        children: data.remarks,
      }),
    },
  ]

  return (
    <Table
      bodyStyle={{
        fontSize: 12,
        overflowX: 'auto',
      }}
      columns={columns}
      dataSource={prList}
      size="small"
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50'],
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        position: 'both',
      }}
      bordered
    />
  )
}

PurchaseRequestTable.propTypes = {
  prList: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
}

export default PurchaseRequestTable;
