import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Utils = createContext({});

const UtilsContext = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Utils.Provider value={{ loading, setLoading }}>{children}</Utils.Provider>
  );
};

UtilsContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UtilsContext;
