export const GET_PO = 'GET_PO';
export const ADD_INVOICE = 'ADD_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const DELETE_PO = 'DELETE_PO';
export const UPDATE_PO = 'UPDATE_PO';
export const GET_POITEMS = 'GET_POITEMS';
export const UPDATE_POITEM = 'UPDATE_POITEM';

export const reducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
  case GET_PO:
    return {
      ...state,
      poList: payload.poList,
      supplierOption: payload.supplierList,
    }
  case UPDATE_PO:
    return {
      ...state,
      poList: [
        ...state.poList.slice(0, state.poList.findIndex((po) => po.id === payload.newPo.id)),
        payload.newPo,
        ...state.poList.slice(state.poList.findIndex((po) => po.id === payload.newPo.id) + 1),
      ],
    }
  case DELETE_PO:
    return {
      ...state,
      poList: state.poList.filter((po) => po.id !== payload.id),
    }
  case GET_POITEMS:
    return {
      ...state,
      poItems: payload.poItems,
      supplierOption: payload.supplierList,
    }
  case UPDATE_POITEM:
    return {
      ...state,
      poItems: [
        ...state.poItems.slice(0, state.poItems.findIndex((po) => po.id === payload.newItem.id)),
        payload.newItem,
        ...state.poItems.slice(state.poItems.findIndex((po) => po.id === payload.newItem.id) + 1),
      ],
    }
  default:
    return state;
  }
}
